<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="explanation" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('explanation')">
                            <b-form-textarea v-model="updateData.explanation" :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <b-col cols="12" class="d-flex justify-content-center">
                    <b-button variant="danger" class="mt-0" @click="update">
                        {{ $t("reject") }}
                    </b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import UndergraduateTransferService from "@/services/UndergraduateTransferService";

export default {
    name: "AcademicUnitOfficeURejectForm",
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        transferPreferenceId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            updateData: {
                undergraduate_transfer_preference_id: null,
                explanation: null,
            },
        };
    },
    methods: {
        async update() {
            const valid = await this.$refs.formModalValidate.validate();
            if (!valid) {
                return;
            }
            this.updateData.undergraduate_transfer_preference_id =
                this.transferPreferenceId;
            UndergraduateTransferService.declinePreference(this.updateData)
                .then((response) => {
                    const data = response.data.data;
                    this.$emit("updated", data);
                    this.updateData = {
                        undergraduate_transfer_preference_id: null,
                        explanation: null,
                    };
                })
                .catch((e) => {
                    this.showErrors(e);
                });
        },
    },
};
</script>
